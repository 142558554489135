<template>
  <b-img
    width="80"
    :src="require('@/assets/images/logo/logo-dark.png')"
    class="badge-minimal"
  />
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>
